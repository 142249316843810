import httpService from '@viteplan/services/http.service';

export const saveTheDateList = (eventGroupId: number | undefined) => {
  return (
    httpService
      .get(`save-the-date-template/user-template?eventGroupId=${eventGroupId}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

export const saveTheDateTemplateById = (templateId: number, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`save-the-date-template/${templateId}`, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveTheDateUserTemplate = (body: any, config = {}) => {
  return (
    httpService
      .post(`save-the-date-template/user-template`, body, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteUserTemlate = (userTemplateId: number) => {
  return (
    httpService
      .delete(`save-the-date-template/user-template/${userTemplateId}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

export const saveTheDateUserTemplateById = (templateId: number) => {
  return (
    httpService
      .get(`save-the-date-template/user-template/${templateId}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};
