/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventType } from '../models/event-type.model';

export const mapDataToEventForm = (data: any) => {
  const result: any = {
    eventType: { id: data.eventType?.id, name: data.eventType?.name } as EventType,
    name: data.name,
    date: data.date,
    startTime: data.startTime,
    endTime: data.endTime,
    venue: data.venue,
    address: data.address,
    floor: data.floor,
    city: data.city,
    zip: data.zip,
    country: {
      id: data.country?.id,
      name: data.country?.name
    },
    notes: data.notes,
    hasMeal: data.hasMeal,
    hasSchedule: data.hasSchedule,
    hasNotes: data.hasNotes,
    guestsAllowedToAddGuest: data.guestsAllowedToAddGuest,
    noOfGuestsAllowed: data.noOfGuestsAllowed,
    meals: meals(data.meals),
    eventSchedules: eventSchedule(data.eventSchedules),
    isPublic: false,
    isRSVPRequired: data.isRSVPRequired,
    isRSVPRestricted: data.isRSVPRestricted,
    rsvpEndDate: data.rsvpEndDate
  };

  if (data.state?.id) {
    result.state = {
      id: data.state.id,
      name: data.state.name
    };
  }

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const eventSchedule = (data: any) => {
  const events: any = [];
  // eslint-disable-next-line array-callback-return
  data?.map((item: any) => {
    events.push({
      name: item.name,
      startTime: item.startTime,
      endTime: item.endTime,
      note: item.note
    });
  });
  return events;
};

const meals = (data: any) => {
  const meal: any = [];
  // eslint-disable-next-line array-callback-return
  data?.map((item: any) => {
    meal.push({
      name: item.name,
      isveg: item.isveg,
      id: item.id
    });
  });
  return meal;
};

export const getSortedEvents = (events: any[]) => {
  return events
    .filter((event) => {
      // Check if 'event' property exists and it has 'date' and 'startTime' properties
      return (
        event &&
        event.event &&
        event.event.date !== null &&
        event.event.date !== undefined &&
        event.event.startTime !== null &&
        event.event.startTime !== undefined
      );
    })
    .slice()
    .sort((a: any, b: any) => {
      const dateA = new Date(a.event.date);
      const dateB = new Date(b.event.date);

      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }

      const timeA = parseTime(a.event.startTime);
      const timeB = parseTime(b.event.startTime);

      if (timeA !== null && timeB !== null) {
        return timeA - timeB;
      } else if (timeA !== null) {
        return -1; // If timeA is not null, but timeB is null, consider timeA smaller
      } else if (timeB !== null) {
        return 1; // If timeB is not null, but timeA is null, consider timeB smaller
      } else {
        return 0; // Both times are null, consider them equal
      }
    });
};

const parseTime = (timeString: string | null | undefined) => {
  // Check if timeString is null or undefined
  if (timeString == null) {
    return null; // or you can return some default value, depending on your use case
  }
  const [hours, minutes] = timeString.split(':').map(Number);
  const utcDate = new Date(0); // Create a UTC date
  utcDate.setUTCHours(hours, minutes);
  return utcDate.getTime();
};

export const hasRsvpExpired = (rsvpEndDate: any) => {
  const currentDate = new Date();
  const endDate = new Date(rsvpEndDate);
  endDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);
  if (rsvpEndDate === null) {
    return true;
  }
  if (endDate >= currentDate) {
    return true;
  } else {
    return false;
  }
};

export const countRSVPAnswers = (event: any, data: any) => {
  let totalCount = 0;
  let yesCount = 0;
  let noCount = 0;
  let notReplyCount = 0;
  const yesGuests: any[] = [];
  const noGuests: any[] = [];
  const noReplyGuests: any[] = [];
  const eventId: any = event?.[0]?.event?.id;
  data.forEach((event: any) => {
    event.guests.forEach((guest: any) => {
      totalCount++;
      const matchRsvp = (element: any) => element.eventId === eventId;
      if (!guest.rsvpAnswers.some(matchRsvp)) {
        notReplyCount++;
        noReplyGuests.push(guest);
      }
      guest.rsvpAnswers.forEach((answer: any) => {
        if (answer.eventId === eventId) {
          if (answer.answer.toLowerCase() === 'yes') {
            yesCount++;
            yesGuests.push(guest);
          }
          if (answer.answer.toLowerCase() === 'no') {
            noCount++;
            noGuests.push(guest);
          }
        }
      });
    });
  });
  return {
    yesCount,
    noCount,
    yesGuests,
    noGuests,
    noReplyGuests,
    event,
    notReplyCount,
    totalCount
  };
};
