import React from 'react';
import LottieLoader from 'react-lottie-loader';
import catAnimationData from '../../assets/img/loader/loader.json';

function Loader() {
  return (
    <div className="loader_container p-5">
      <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
        {/* <img src={loaderIcon} style={{ width: '100px' }} alt="" /> */}
        <LottieLoader animationData={catAnimationData} style={{ height: 'auto', width: '200px' }} />
        <p>Preparing a delightful experience just for you. Please wait while we load the magic.</p>
      </div>
    </div>
  );
}

export default Loader;
