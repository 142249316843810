/**
 * @summary Formats the provided date string into a Date object.
 * @memberof Util {@link Util}
 *
 * @param {string} date - The date string to format.
 *   @example "2023-08-06"
 * @returns {Date | null} - The formatted date object or null if the input is invalid.
 *  @example Sun Aug 06 2023 05:30:00 GMT+0530 (India Standard Time)
 */
export const parseDateStringToDate = (dateString: string): Date | null => {
  if (dateString == null) return null;

  const dateObj = new Date(dateString);
  return dateObj;
};

/**
 * @summary Formats the provided Date object into a localized date string.
 * @memberof Util {@link Util}
 *
 * @param {Date} dateObj - The Date object to format.
 *  @example Sun Aug 06 2023 05:30:00 GMT+0530 (India Standard Time)
 * @returns {string} - The formatted date string.
 *  @example 'Sunday, August 6, 2023'
 */
export const formatLocalizedDate = (apiDate: string): string => {
  const [year, month, date] = apiDate.split('-').map(Number);

  const weekdayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const dayOfWeek = weekdayNames[new Date(year, month - 1, date).getDay()];
  const monthName = monthNames[month - 1];

  return `${dayOfWeek}, ${monthName} ${date}, ${year}`;
};

/**
 * @summary Formats the provided Date object into a string in the format "MM-DD-YYYY".
 * @memberof Util {@link Util}
 *
 * @param {Date} dateObj - The Date object to format.
 * @returns {string} - The formatted date string. mm-dd-yyyy
 */
export const formatDateToMonthDayYear = (dateObj: Date): string => {
  return `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}
          -${dateObj.getDate().toString().padStart(2, '0')}
          -${dateObj.getFullYear().toString()}`;
};

/**
 * @summary Formats the provided time string (format "HH:mm") into a 12-hour time string with AM/PM.
 * @memberof Util {@link Util}
 *
 * @param {string} time - The time string to format ("HH:mm").
 * @returns {string} - The formatted time string in 12-hour format.
 */
export const formatTimeTo12HourClock = (time: string): string => {
  const hour = Number(time.slice(0, 2)) % 12 || 12;
  const minute = time.slice(3, 5);
  const period = Number(time.slice(0, 2)) >= 12 ? 'PM' : 'AM';
  return `${hour}:${minute} ${period}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formateTime = (timestamp: any) => {
  const date = new Date(timestamp);
  const now = new Date();

  const isSameDay =
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  let timeString;

  if (hours >= 12) {
    // PM
    if (hours > 12) {
      // Convert to 12-hour format if necessary
      timeString = `${hours - 12}:${minutes} PM`;
    } else {
      timeString = `${hours}:${minutes} PM`;
    }
  } else {
    // AM
    if (hours === 0) {
      timeString = `12:${minutes} AM`; // Midnight
    } else {
      timeString = `${hours}:${minutes} AM`;
    }
  }

  if (isSameDay) {
    return `Today ${timeString}`;
  } else if (
    date.getDate() === now.getDate() - 1 &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    return `Yesterday ${timeString}`;
  } else {
    return `${date.toLocaleDateString()}`;
  }
};
