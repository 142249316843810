/* eslint-disable @typescript-eslint/no-explicit-any */
import { DropDown } from '@viteplan/components/common/DropDown';
import { getSelectedEventGroup } from '@viteplan/services/user.service';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { countRSVPAnswers } from 'views/Events/services/event-helper.service';
import ThreeDotsLoader from 'views/Loader/ThreeDotsLoader';
import { mapDataToRsvpCountDropdown } from '../services/guest-helper.service';
import { invitedGuestInEvent, rsvpResponsesCount } from '../services/guest.service';
import { Guest } from '../models/guest.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function RsvpResponses() {
  const { auth } = useAuth();
  const noDiv = useRef<any>();
  const notRespondDiv = useRef<any>();
  const [events, setEvents] = useState<any>([]);
  const [filteredEvents, setFilteredEvents] = useState<any>('');
  const [rsvpResponses, setRsvpResponses] = useState<any>('');
  const [invitedGuestDetails, setInvitedGuestDetails] = useState<any>();
  const [showLoader, setShowloader] = useState<boolean>(false);

  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const userEventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);

  const scrollToNoDiv = () => {
    if (noDiv) {
      noDiv?.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToNotRespondDiv = () => {
    if (notRespondDiv) {
      notRespondDiv?.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getRsvpResponsesCount = async (eventGroupId: number | undefined) => {
    try {
      const response = await rsvpResponsesCount(eventGroupId);
      if (response.data.data) {
        const newEvents = response.data.data.filter((item: any) => item.event.isRSVPRequired);
        const eventId = newEvents.length > 0 ? newEvents[0].event.id : undefined;
        if (eventId !== undefined) {
          await getInvitedGuestInEvent(eventGroupId, eventId);
        }
        setEvents(newEvents);
        setFilteredEvents(newEvents.slice(0, 1));
      }
    } catch (e) {
      console.log('Error Occurred:', e);
    }
  };

  const getInvitedGuestInEvent = async (
    eventGroupId: number | undefined,
    eventId: number | undefined
  ) => {
    try {
      if (eventId !== undefined) {
        const response = await invitedGuestInEvent(eventGroupId, eventId);
        if (response.data.data) {
          setInvitedGuestDetails(response.data.data || []);
        } else {
          setInvitedGuestDetails([]);
        }
      }
    } catch (error) {
      console.log('Error Occurred:', error);
    } finally {
      setShowloader(false);
    }
  };

  const handleEventSelection = async (event: any) => {
    setShowloader(true);
    const data = events?.filter((item: any) => item.event.name === event.label);
    try {
      const invitedGuestDetails = await getInvitedGuestInEvent(userEventGroup?.id, event.value);
      console.log(invitedGuestDetails);
      setFilteredEvents(data);
    } catch (error) {
      console.log('Error occurred while fetching invited guest details:', error);
    }
  };

  useEffect(() => {
    if (userEventGroup) getRsvpResponsesCount(userEventGroup?.id);
  }, []);

  useEffect(() => {
    if (filteredEvents.length > 0) {
      const rsvpData = countRSVPAnswers(filteredEvents, invitedGuestDetails);
      setRsvpResponses(rsvpData);
    }
  }, [filteredEvents]);

  const handleDownloadClick = async () => {
    try {
      const link = document.createElement('a');
      link.href = process.env.REACT_APP_BASE_URL + `guest/exportExcel/${userEventGroup?.id}`;
      link.setAttribute('download', 'excel file');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success('Your file download has been started');
    } catch (error) {
      console.error('Error downloading the file:', error);
      toast.error('Error downloading the file. Please try again later.');
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
      <div className="rsvp_events_list">
        <div className="export_btn">
          <h2>Events</h2>
          <button type="button" className="btn primary" onClick={handleDownloadClick}>
            Export to Excel
          </button>
        </div>
        {events && events.length > 0 && (
          <DropDown
            id="eventName"
            name="eventName"
            offSet={13}
            onChange={(option: { value: number; label: string }) => {
              handleEventSelection(option);
            }}
            options={mapDataToRsvpCountDropdown(events)}
            selectedOptions={{ name: events?.[0].event?.name, id: events?.[0].event?.id }}
          />
        )}
      </div>
      {events && !showLoader && events.length > 0 && filteredEvents.length > 0 ? (
        filteredEvents.map((item: any) => {
          return (
            <>
              <div className="rsvp_status">
                <h2>{item?.event?.name}</h2>
                <div className="rsvp_response_wrapper">
                  <div className="rsvp_response_count">
                    <span style={{ backgroundColor: '#f2f3f1', border: '1px solid #e5e7e4' }}>
                      {rsvpResponses?.totalCount}
                    </span>
                    <p>Invitation list</p>
                  </div>
                  <div className="rsvp_response_count">
                    <span style={{ backgroundColor: '#e3fbe4', border: '1px solid #e4f1d8' }}>
                      {rsvpResponses?.yesCount}
                    </span>
                    <p>Yes</p>
                  </div>
                  <div className="rsvp_response_count" onClick={scrollToNoDiv}>
                    <span style={{ backgroundColor: '#ffece9', border: '1px solid #f1d7d2' }}>
                      {rsvpResponses?.noCount}
                    </span>
                    <p>No</p>
                  </div>
                  <div className="rsvp_response_count" onClick={scrollToNotRespondDiv}>
                    <span style={{ backgroundColor: '#f2f3f1', border: '1px solid #e8eae7' }}>
                      {rsvpResponses?.notReplyCount}
                    </span>
                    <p>No reply</p>
                  </div>
                </div>
              </div>
              <div className="rsvp_guest_list">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className=" rsvp_response accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                      <button
                        className=" accordion-button"
                        type="button"
                        style={{ backgroundColor: '#e3fbe4' }}
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne">
                        <div className="rsvp_response_header">
                          <span className="">Yes</span>
                          <span className="">{rsvpResponses?.yesCount}</span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne">
                      <div className="accordion-body">
                        {rsvpResponses?.yesGuests?.map((guest: Guest, index: number) => (
                          <div className="rsvp_guest_names_response" key={index}>
                            <div className="rsvp_guest_names">{guest?.firstName}</div>
                            <div className="rsvp_guest_email">{guest?.email}</div>
                            <div className="rsvp_guest_phone">{guest?.mobile}</div>
                            {/* <div className="rsvp_guest_message">
                        <i className="fal fa-comment-alt-lines"></i>
                      </div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="rsvp_response accordion-item" ref={noDiv}>
                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        style={{ backgroundColor: '#ffece9' }}
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo">
                        <div className="rsvp_response_header">
                          <span className="">No</span>
                          <span className="">{rsvpResponses?.noCount}</span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingTwo">
                      <div className=" accordion-body">
                        {rsvpResponses?.noGuests?.map((guest: Guest, index: number) => (
                          <div className="rsvp_guest_names_response" key={index}>
                            <div className="rsvp_guest_names">{guest?.firstName}</div>
                            <div className="rsvp_guest_email">{guest?.email}</div>
                            <div className="rsvp_guest_phone">{guest?.mobile}</div>
                            {/* <div className="rsvp_guest_message">
                        <i className="fal fa-comment-alt-lines"></i>
                      </div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="rsvp_response accordion-item" ref={notRespondDiv}>
                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        style={{ backgroundColor: '#f5f5f7' }}
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree">
                        <div className="rsvp_response_header">
                          <span className="">No reply</span>
                          <span className=""> {rsvpResponses?.notReplyCount}</span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingThree">
                      <div className=" accordion-body">
                        {rsvpResponses?.noReplyGuests?.map((guest: Guest, index: number) => (
                          <div className="rsvp_guest_names_response" key={index}>
                            <div className="rsvp_guest_names">{guest?.firstName}</div>
                            <div className="rsvp_guest_email">{guest?.email}</div>
                            <div className="rsvp_guest_phone">{guest?.mobile}</div>
                            {/* <div className="rsvp_guest_message">
                        <i className="fal fa-comment-alt-lines"></i>
                      </div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {' '}
          <ThreeDotsLoader />
        </div>
      )}
    </>
  );
}

export default RsvpResponses;
