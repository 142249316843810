import httpService from '@viteplan/services/http.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addMemories = (eventGroupId: number | undefined, body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    httpService
      .post(`event-group/${eventGroupId}/assets`, body, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addGreetings = (body: any, config = {}) => {
  return (
    httpService
      .post(`greeting`, body, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

export const GreetingList = (eventGroupId: number | undefined, config = {}) => {
  return (
    httpService
      .get(`greeting/event-group/${eventGroupId}`, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any[]) => res)
  );
};

export const greetingByGuest = (eventGroupId: number, email: string) => {
  return httpService.get(`greeting/event-group/${eventGroupId}/${email}`);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const greetingCardLayout = (body: any) => {
  return httpService.post(`greeting/master`, body);
};

export const getGreetingcardLayout = (eventGroupId: number | undefined) => {
  return httpService.get(`greeting/master/eg/${eventGroupId}`);
};
