import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { inviteCollaborator, userAccount } from '../service/profile.service';

export const getAllUserAccount = createAsyncThunk(
  'userAccount',
  async (eventGroupId: number | undefined) => {
    const response = await userAccount(eventGroupId);
    return response.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addCoAdmin = createAsyncThunk('addAdmin', async (data: any) => {
  const response = await inviteCollaborator(data);
  return response.data;
});

interface UserProfile {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  loading: boolean;
  error: string | null;
}

const initialState: UserProfile = {
  user: [],
  loading: false,
  error: null
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Co Admins list
    builder.addCase(getAllUserAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllUserAccount.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.user = data;
    });
    builder.addCase(getAllUserAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    // Add Ndew Co Admin
    builder.addCase(addCoAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCoAdmin.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      if (data) state.user.push(data);
    });
    builder.addCase(addCoAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});

export default userProfileSlice.reducer;
