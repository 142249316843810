import { useGlobalDrawerContext } from 'context-api/GlobalDrawerContext';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { useEffect } from 'react';
import { getAdminTemplatesTheme } from 'views/WeddingPlan/slice/admin-design.slice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function WebsiteTheme(props: any) {
  const { updateWebsiteTheme } = props.data;
  const { adminTemplatesThemes } = useAppSelector((state) => state.adminDesign);
  const { hideDrawer } = useGlobalDrawerContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAdminTemplatesTheme());
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateTheme = (data: any) => {
    updateWebsiteTheme(data);
    hideDrawer();
  };

  return (
    <section className="create_invitation " style={{ backgroundImage: 'none' }}>
      <div className=" mb-0 width_1600 d-flex flex-column align-items-center">
        <div className="new_invitaion_list">
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {adminTemplatesThemes.map((item: any, index: number) => {
            return (
              <div className="invitation-box">
                <div className="invitation_card_show">
                  <img
                    key={index}
                    src={item.adminTemplate.thumbnailLocation}
                    alt=""
                    onClick={() => updateTheme(item.adminTemplate)}
                  />
                </div>
                <div className="card_text_panel">
                  <h2 className="title_small">{item?.adminTemplate.name}</h2>
                  <p className="small_text">{item?.adminTemplate.description}</p>
                </div>
              </div>
            );
          })}
        </div>
        {/* <LinkButton label="Custom Designs" link="/custom-design-form" /> */}
      </div>
    </section>
  );
}

export default WebsiteTheme;
