/* eslint-disable array-callback-return */
import {
  eventSelection,
  guestSelection
} from '@viteplan/components/ReduxStore/InvitationDrawerTitle';
import { getSelectedEventGroup } from '@viteplan/services/user.service';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useGlobalDrawerContext } from '../../../context-api/GlobalDrawerContext';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import useAuth from '../../../hooks/useAuth';
import { Event } from '../../Events/models/event.model';
import { AddEditEvent } from '../../Events/partials/AddEditEvent';
import { getAllEvents } from '../../Events/slice/event-slice';
// import AddIndividualGuest from '../../Guests/partials/AddIndividualGuest';
import { getAllGuests } from '../../Guests/slice/guest.slice';
import EventSelectionItem from './EventSelectionItem';
import GuestSelection from 'views/Guests/partials/GuestSelection';
import { Guest } from 'views/Guests/models/guest.model';

function InvitationMeta() {
  const { auth } = useAuth();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const updateDrawerTitle = useAppDispatch();

  const { eventGroup } = useAppSelector((state) => state.event);
  const { guests } = useAppSelector((state) => state.guest);
  const { userTemplate } = useAppSelector((state) => state.editor);

  const [selectedGuests, setSelectedGuests] = useState<Guest[]>();
  const [showEventSelection, setShowEventSelection] = useState(true);
  const [showAddEventForm, setShowAddEventForm] = useState(false);
  const [showGuestSelection, setShowGuestSelection] = useState(false);
  const [hideGuestBackButtonIcon, setHideGuestBackButtonIcon] = useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [eventArray, setEvents] = useState<any>(
    state?.invitationObj?.events ? [...state?.invitationObj?.events] : []
  );

  const initialEventsCheckboxState = eventGroup?.events?.map((item: Event) => {
    // Check if the item ID exists in the other data array
    const isMatch = eventArray?.some((event: Event) => event.id === item.id) ? true : false;
    return isMatch;
  });

  const [eventsCheckboxState, setEventsCheckboxState] = useState(initialEventsCheckboxState);

  const { hideDrawer } = useGlobalDrawerContext();
  const navigate = useNavigate();

  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const userEventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEventChange = (e: any, index: number) => {
    const eventData = e.target._wrapperState.initialValue;
    const checked = e.target.checked;
    const newCheckboxState = [...eventsCheckboxState];
    newCheckboxState[index] = !newCheckboxState[index];
    setEventsCheckboxState(newCheckboxState);
    const newValue = checked
      ? [...eventArray, eventData]
      : eventArray.filter((val: Event) => val.id !== eventData.id);
    setEvents([...newValue]);
  };

  useEffect(() => {
    setEventsCheckboxState(initialEventsCheckboxState);
  }, [eventGroup]);

  const prepareInvitationObject = () => {
    try {
      const stateObject = {
        invitationId: state?.invitationObj ? state?.invitationObj?.id : null,
        events: [...eventArray],
        guest: selectedGuests,
        userTemplate: userTemplate,
        invitationName: state.invitationObj ? state?.invitationObj.name : ''
      };
      navigate('/checkout', { state: stateObject });
      hideDrawer();
    } catch (e) {
      console.log('Error Occured --->', e);
    }
  };

  useEffect(() => {
    dispatch(getAllEvents(userEventGroup?.id));
    dispatch(getAllGuests(userEventGroup?.id));
    updateDrawerTitle(eventSelection());
  }, []);

  useEffect(() => {
    if (selectedGuests && selectedGuests.length > 0) prepareInvitationObject();
  }, [selectedGuests]);

  const getSelectedGuests = (data: Guest[]) => {
    setSelectedGuests(data);
  };

  const hideGuestButton = (data: boolean) => {
    setHideGuestBackButtonIcon(data);
  };

  if (showEventSelection) {
    return (
      <>
        <div className="d-flex flex-column h-100 gap-3 overflow-hidden">
          <div className="d-flex gap-3">
            <div className="input_with_search" style={{ visibility: 'hidden' }}>
              <input type="text" />
              <span className="icon_panel">
                <i className="fal fa-search"></i>
              </span>
            </div>
            <button
              className="btn primary d-inline-flex gap-2"
              style={{ whiteSpace: 'nowrap', padding: '0 8px', minWidth: 'unset' }}
              onClick={() => {
                setShowEventSelection(false);
                setShowAddEventForm(true);
              }}>
              <i className="fal fa-plus"></i> Add New Event
            </button>
          </div>
          <div className="event_list_container d-flex flex-column gap-3">
            {eventsCheckboxState &&
              eventGroup?.events?.map((event: Event, index: number) => {
                return (
                  <EventSelectionItem
                    key={event.id}
                    data={event}
                    onChange={(e: Event) => handleEventChange(e, index)}
                    selectedItem={event}
                    editable={state}
                    isChecked={eventsCheckboxState[index] || false}
                  />
                );
              })}
          </div>
        </div>
        <button
          className={eventArray.length !== 0 ? 'btn primary' : 'btn disabled'}
          style={{ maxWidth: 'fit-content' }}
          onClick={() => {
            setShowEventSelection(false);
            setShowGuestSelection(true);
            updateDrawerTitle(guestSelection());
          }}>
          Add Guest
        </button>
      </>
    );
  }

  if (showAddEventForm) {
    return (
      <>
        <button
          className="fit-to-content without_background with-icon"
          style={{
            border: 'none',
            background: 'none',
            width: 'auto',
            textAlign: 'left',
            paddingBottom: '10px'
          }}
          onClick={() => {
            setShowAddEventForm(false);
            setShowEventSelection(true);
            updateDrawerTitle(eventSelection());
          }}>
          <i className="far fa-long-arrow-left"></i> Back
        </button>
        <AddEditEvent
          drawerOpenState={false}
          setDrawerOpenState={() => {
            setShowAddEventForm(false);
            setShowEventSelection(true);
          }}
        />
      </>
    );
  }

  if (showGuestSelection) {
    return (
      <>
        {hideGuestBackButtonIcon && (
          <button
            className="fit-to-content without_background with-icon"
            style={{
              border: 'none',
              background: 'none',
              width: 'auto',
              textAlign: 'left',
              paddingBottom: '10px'
            }}
            onClick={() => {
              setShowEventSelection(true);
              updateDrawerTitle(eventSelection());
            }}>
            <i className="far fa-long-arrow-left"></i> Back
          </button>
        )}
        <GuestSelection
          guestInfo={guests}
          onGuestSave={getSelectedGuests}
          hideBacKButton={hideGuestButton}
        />
      </>
    );
  }
}

export default InvitationMeta;
