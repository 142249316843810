import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addGuest, deleteGuest, listGuests } from '../services/guest.service';

export const getAllGuests = createAsyncThunk(
  'getGuest',
  async (eventGroupId: number | undefined) => {
    const response = await listGuests(eventGroupId);
    return response.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addGuestData = createAsyncThunk('addGuest', async (data: any) => {
  const response = await addGuest(data);
  return response.data;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const editGuestData = createAsyncThunk('editGuest', async (data: any) => {
  const response = await addGuest(data);
  return response.data;
});

//Delete Guest Data
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteGuestData = createAsyncThunk('deleteGuest', async (data: any) => {
  await deleteGuest({ data: data });
  return data;
});

export const clearGuestError = createAction('guest/clearError');

interface GuestState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  guests: any;
  loading: boolean;
  error: string | null;
}

const initialState: GuestState = {
  guests: [],
  loading: false,
  error: null
};

export const guestSlice = createSlice({
  name: 'guests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllGuests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllGuests.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.guests = data;
    });
    builder.addCase(getAllGuests.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Add Guest Data
    builder.addCase(addGuestData.pending, (state) => {
      console.log(state);
    });
    builder.addCase(addGuestData.fulfilled, (state, action) => {
      const { data } = action.payload;
      if (data) {
        console.log(data);
        state.error = null;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(addGuestData.rejected, (state, action) => {
      state.error = action.error.message as string;
    });

    //Edit Guest Data
    builder.addCase(editGuestData.pending, (state) => {
      console.log(state);
    });
    builder.addCase(editGuestData.fulfilled, (state, action) => {
      const { data } = action.payload;
      if (data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        state.guests.map((item: any, index: number) => {
          if (item.id === data.id) state.guests[index] = data;
          return null;
        });
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(editGuestData.rejected, (state, action) => {
      state.error = action.error.message as string;
    });

    //DELETE Guest
    builder.addCase(deleteGuestData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteGuestData.fulfilled, (state, action) => {
      state.loading = false;
      state.guests = state.guests.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => !action.payload.includes(item.id)
      );
    });
    builder.addCase(deleteGuestData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //For clearing error Message
    builder.addCase(clearGuestError, (state) => {
      state.error = null;
    });
  }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default guestSlice.reducer;
