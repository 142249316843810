import { createSlice } from '@reduxjs/toolkit';

interface LoginState {
  loggedIn: boolean;
}

const initialState = {
  loggedIn: false
} as LoginState;

const loginSlice = createSlice({
  name: 'loginReducerSlice',
  initialState,
  reducers: {
    handleLogin(state) {
      state.loggedIn = true;
    },
    handleLogout(state) {
      state.loggedIn = false;
    }
  }
});

export const { handleLogin, handleLogout } = loginSlice.actions;
export default loginSlice.reducer;
