/* eslint-disable @typescript-eslint/no-explicit-any */
import httpService from '@viteplan/services/http.service';
import { EventType } from '../models/event-type.model';
// import { Event } from '../models/event.model';

export const eventLists = (eventGroupId: number | undefined, config = {}) => {
  // Change any to EventGroup
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`event-group/${eventGroupId}`, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addEvent = (eventGroupId: number | undefined, body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`event/${eventGroupId}`, body, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteEvent = (config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.delete(`event`, config).then((res: any) => res);
};

export const eventTypes = (config = {}) => {
  return httpService.get(`master-data/event-type`, config).then((res: EventType[]) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateWeddingDate = (eventGroupId: number | undefined, body: any) => {
  return httpService.post(`event/${eventGroupId}/weddingDate`, body).then((res: any) => res);
};
