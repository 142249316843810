import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createChatGroup, groupMessage, sendMessage } from '../services/message.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendMessageData = createAsyncThunk('sendMessage', async (data: any) => {
  const response = await sendMessage(data);
  return response.data;
});

export const getGroupMessage = createAsyncThunk(
  'gorupMessage',
  async ({
    eventGroupId,
    guestId,
    userId
  }: {
    eventGroupId: number | undefined;
    guestId: number | undefined;
    userId: number | undefined;
  }) => {
    const response = await groupMessage(eventGroupId, guestId, userId);
    return response.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addNewChatGroup = createAsyncThunk('chatGroup', async (data: any) => {
  const response = createChatGroup(data);
  return response.data;
});

interface Message {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupMessage: any;
  loading: boolean;
  error: string | null;
}

const initialState: Message = {
  message: [],
  groupMessage: [],
  loading: false,
  error: null
};

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Send Message
    builder.addCase(sendMessageData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendMessageData.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.message.push(data);
    });
    builder.addCase(sendMessageData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //get Group Message
    builder.addCase(getGroupMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGroupMessage.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.groupMessage = data;
    });
    builder.addCase(getGroupMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Add New Group
  }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default messageSlice.reducer;
