import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addInvitation,
  deleteInvitation,
  invitationDetailById,
  invitationList
} from '../service/invitation.service';

export const getAllInvitation = createAsyncThunk(
  'invitationList',
  async (eventGroupId: number | undefined) => {
    const response = await invitationList(eventGroupId);
    return response.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addInvitationTemplate = createAsyncThunk('invitationTemplate', async (data: any) => {
  const response = await addInvitation(data);
  return response.data;
});

//Delete Invitation Data
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteInvitationData = createAsyncThunk('deleteInvitaion', async (data: any) => {
  await deleteInvitation(data);
  return data;
});

//Get Invitation By id
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInvitationDetailsById = createAsyncThunk('invitationById', async (data: any) => {
  const response = await invitationDetailById(data);
  return response.data;
});

interface InvitationTemplate {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invitation: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invitationById: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newInvitation: any;
  loading: boolean;
  error: string | null;
}

const initialState: InvitationTemplate = {
  invitation: [],
  invitationById: {},
  newInvitation: {},
  loading: false,
  error: null
};

export const invitationSlice = createSlice({
  name: 'invitationTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get Invitation template
    builder.addCase(getAllInvitation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllInvitation.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.invitation = data;
    });
    builder.addCase(getAllInvitation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Add Invitation template
    builder.addCase(addInvitationTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addInvitationTemplate.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      if (!state.invitation) {
        state.invitation = [];
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const index = state.invitation.findIndex((item: any) => item.id === data.id);
      if (index !== -1) {
        state.invitation[index] = data;
      } else {
        state.invitation.push(data);
      }
    });
    builder.addCase(addInvitationTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //DELETE Invitation
    builder.addCase(deleteInvitationData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteInvitationData.fulfilled, (state, action) => {
      state.loading = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state.invitation = state.invitation.filter((item: any) => item.id !== action.payload);
    });
    builder.addCase(deleteInvitationData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Get Invitation By Id
    builder.addCase(getInvitationDetailsById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInvitationDetailsById.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.invitationById = data;
    });
    builder.addCase(getInvitationDetailsById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});

export default invitationSlice.reducer;
