import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPreview: false
};

const websiteSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    websitePreview: (state) => {
      state.isPreview = true;
    }
  }
});

export const { websitePreview } = websiteSlice.actions;
export default websiteSlice.reducer;
