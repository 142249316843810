import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  adminTemplateById,
  adminTemplateList,
  adminTemplateThemes
} from '../service/template.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAdminTemplateLists = createAsyncThunk('adminDesign', async () => {
  const response = await adminTemplateList();
  return response.data;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAdminTemplateById = createAsyncThunk('adminDesignById', async (id: any) => {
  const response = await adminTemplateById(id);
  return response.data;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAdminTemplatesTheme = createAsyncThunk('adminTemplatesTheme', async () => {
  const response = await adminTemplateThemes();
  return response.data;
});

interface AdminTemplateState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  adminTemplate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  adminTemplateById: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  adminTemplatesThemes: any;
  loading: boolean;
  error: string | null;
}

const initialState: AdminTemplateState = {
  adminTemplate: [],
  adminTemplateById: {},
  adminTemplatesThemes: [],
  loading: false,
  error: null
};

export const adminDesignSlice = createSlice({
  name: 'adminDesign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get Admin Template
    builder.addCase(getAdminTemplateLists.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdminTemplateLists.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.adminTemplate = data;
    });
    builder.addCase(getAdminTemplateLists.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Get admin template by id
    builder.addCase(getAdminTemplateById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdminTemplateById.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.adminTemplateById = data;
    });
    builder.addCase(getAdminTemplateById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Get Admin Template Themes
    builder.addCase(getAdminTemplatesTheme.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdminTemplatesTheme.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.adminTemplatesThemes = data;
    });
    builder.addCase(getAdminTemplatesTheme.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});

export default adminDesignSlice.reducer;
