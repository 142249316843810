import { getSelectedEventGroup } from '@viteplan/services/user.service';
import {
  DRAWER_INNER_COMPONENTS_TYPE,
  useGlobalDrawerContext
} from 'context-api/GlobalDrawerContext';
import React from 'react';
import { NavLink } from 'react-router-dom';
/**
 * @memberof DropDown
 */
/**
 * The props type for {@link ButtonDropDown}.
 *
 * The props type is defined as a separate interface
 *
 * ```tsx
 *  interface IButtonDropDownProps {
 *    //...
 *  }
 * ```
 *
 * @interface
 */
export interface IButtonDropDownProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logMeOut: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  auth: any;
}

/**
 * Renders a ButtonDropDown around some content.
 *
 * ```tsx
 *  <ButtonDropDown auth={auth} logMeOut={logout} />
 * ```
 *
 * Function implementation
 *
 * ```tsx
 *  function ButtonDropDown({parameters }: IButtonDropDownProps): React.JSX.Element {
 *    //....
 *  }
 * ```
 *
 *
 * @Category Component
 */

export function ButtonDropDown({ auth, logMeOut }: IButtonDropDownProps): React.JSX.Element {
  const { showDrawer } = useGlobalDrawerContext();

  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const eventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);

  const showSwitchAccountDrawer = () => {
    showDrawer(DRAWER_INNER_COMPONENTS_TYPE.SWITCH_ACCOUNT, {
      title: 'Switch Account',
      width: 500,
      isFooterVisible: false
    });
  };

  const userProfileName =
    eventGroup?.eventGroupDetails?.firstName +
    ' & ' +
    eventGroup?.eventGroupDetails?.partnersFirstName;
  return (
    <>
      <div className="dropdown-center user_dropdown">
        <button
          className="btn dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          <span className="user_icon">
            <i className="far fa-user"></i>
          </span>
          <span className="user_name">{userProfileName}</span>
          <i className="far fa-chevron-down" style={{ fontSize: '12px' }}></i>
        </button>
        <ul className="dropdown-menu">
          <li>
            <NavLink className="dropdown-item" aria-current="page" to="/dashboard">
              <i className="far fa-chart-line"></i>Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item" aria-current="page" to="/profile">
              <i className="fal fa-id-card"></i>My Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              className="dropdown-item"
              aria-current="page"
              to="/"
              onClick={() => {
                showSwitchAccountDrawer();
              }}>
              <i className="fal fa-user-circle"></i>Switch Account
            </NavLink>
          </li>
          <li>
            <NavLink to="/xyz" className="dropdown-item" aria-current="page" onClick={logMeOut}>
              <i className="far fa-sign-out"></i> Logout
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}
