import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function GuestSelectionItem(props: any) {
  const { data, onChange, selectedItem, isChecked } = props;
  return (
    <div className="list_box d-flex gap-4">
      <div>
        <div className="Checkbox">
          <input
            type="checkbox"
            id={selectedItem.id}
            value={selectedItem}
            onChange={onChange}
            checked={isChecked}
          />
          <div className="Checkbox-visible"></div>
        </div>
      </div>
      <div className="guest_img">
        <i className="fad fa-user"></i>
      </div>
      <div className="list_text">
        <span className="event_text">
          {data.firstName} {data.lastName}
        </span>
        <span className="event_text"> {data.email}</span>
        <span className="event_text"> {data.mobile}</span>
      </div>
    </div>
  );
}

export default GuestSelectionItem;
