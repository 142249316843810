import { getSelectedEventGroup } from '@viteplan/services/user.service';
import { useGlobalDrawerContext } from 'context-api/GlobalDrawerContext';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { Event } from 'views/Events/models/event.model';
import { getAllEvents } from 'views/Events/slice/event-slice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function EventsDrawer(props: any) {
  const { auth } = useAuth();
  const { eventGroup } = useAppSelector((state) => state.event);
  const dispatch = useAppDispatch();
  const { hideDrawer } = useGlobalDrawerContext();

  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const userEventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);

  const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);
  const { getSelectedEvents } = props.data;

  useEffect(() => {
    dispatch(getAllEvents(userEventGroup?.id));
  }, []);

  const handleCheckboxChange = (event: Event) => {
    const selectedIndex = selectedEvents.findIndex(
      (selectedEvent: Event) => selectedEvent.id === event.id
    );
    if (selectedIndex === -1) {
      setSelectedEvents([...selectedEvents, event]);
    } else {
      const updatedSelectedEvents = [...selectedEvents];
      updatedSelectedEvents.splice(selectedIndex, 1);
      setSelectedEvents(updatedSelectedEvents);
    }
  };

  return (
    <div>
      {eventGroup?.events &&
        eventGroup?.events.map((event: Event, index: number) => (
          <div className="checkbox-box">
            <div className="Checkbox" key={index}>
              <input
                type="checkbox"
                checked={selectedEvents.some(
                  (selectedEvent: Event) => selectedEvent.id === event.id
                )}
                onChange={() => handleCheckboxChange(event)}
              />
              <div className="Checkbox-visible"></div>
            </div>
            <div>
              <label>{event.name}</label>
            </div>
          </div>
        ))}
      <button
        className="btn primary"
        onClick={() => {
          getSelectedEvents(selectedEvents);
          hideDrawer();
        }}>
        Save
      </button>
    </div>
  );
}

export default EventsDrawer;
