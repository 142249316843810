import { TemplateCard } from '@viteplan/components/common/Cards';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { useEffect } from 'react';
import Loader from 'views/Loader/Loader';
import { getAdminTemplatesTheme } from 'views/WeddingPlan/slice/admin-design.slice';

function AddSaveTheDate() {
  const dispatch = useAppDispatch();

  const { adminTemplatesThemes, loading } = useAppSelector((state) => state.adminDesign);

  useEffect(() => {
    dispatch(getAdminTemplatesTheme());
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <section className="create_invitation ">
      <div className=" mb-0 width_1600 d-flex flex-column align-items-center">
        <div className="new_invitaion_list">
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {adminTemplatesThemes?.map((item: any, i: number) => (
            <TemplateCard
              key={i}
              item={item?.saveTheDateTemplate}
              link={`/perpare-save-the-date-editor/${item?.saveTheDateTemplate?.id}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default AddSaveTheDate;
