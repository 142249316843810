import { User } from '@viteplan/models/user.model';
import httpService from '@viteplan/services/http.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addUserProfile = (eventGroupId: number | undefined, body: any, config = {}) => {
  return httpService
    .post(`event-group/${eventGroupId}/details`, body, config)
    .then((res: User) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inviteCollaborator = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`user/invite-user`, body, config).then((res: any) => res);
};

export const getCollaboratorInfo = (uniqueId: string, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`user/invite-user/${uniqueId}`, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateCollaboratorPassword = (uniqueId: string, body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`user/invite-user/${uniqueId}`, body, config).then((res: any) => res);
};

export const userAccount = (eventGroupId: number | undefined, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.get(`users/eg/${eventGroupId}`, config).then((res: any) => res);
};
