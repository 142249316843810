import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  vendorById,
  vendorByUniqueKey,
  vendorList,
  vendorListWithFilters
} from '../services/vendor.service';
import { Vendor } from '../models/Vendor.model';

export const getAllVendorList = createAsyncThunk(
  'vendorList',
  async ({
    pageNo,
    pageSize,
    isActive
  }: {
    pageNo: number;
    pageSize: number;
    isActive: boolean;
  }) => {
    const response = await vendorList(pageNo, pageSize, isActive);
    return response.data;
  }
);

export const getAllVendorListWithFilters = createAsyncThunk(
  'vendorFilterList',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (data: any) => {
    const response = await vendorListWithFilters(data);
    return response.data;
  }
);

export const getVendorById = createAsyncThunk('vendorById', async (requestId: number) => {
  const response = await vendorById(requestId);
  return response.data;
});

export const getVendorByUniqueKey = createAsyncThunk(
  'vendorByUniqueKey',
  async (uniqueKey: string) => {
    const response = await vendorByUniqueKey(uniqueKey);
    return response.data;
  }
);

interface VendorState {
  vendorList: Vendor[];
  vendorListWithFilters: Vendor[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vendorById: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vendorByUniqueKey: Record<string, any>;
  loading: boolean;
  error: null | string;
}

const initialState: VendorState = {
  vendorList: [],
  vendorById: {},
  vendorByUniqueKey: {},
  vendorListWithFilters: [],
  loading: false,
  error: null
};

export const vendorSlice = createSlice({
  name: 'eventGroup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET ALL Vendor List
    builder.addCase(getAllVendorList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllVendorList.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.vendorList = data;
    });
    builder.addCase(getAllVendorList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    // Filter Vendor List
    builder.addCase(getAllVendorListWithFilters.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllVendorListWithFilters.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.vendorListWithFilters = data;
    });
    builder.addCase(getAllVendorListWithFilters.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Vendor Data By ID
    builder.addCase(getVendorById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVendorById.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.vendorById = data;
    });
    builder.addCase(getVendorById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });

    //Vendor Data By Unique Key
    builder.addCase(getVendorByUniqueKey.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVendorByUniqueKey.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.vendorByUniqueKey = data;
    });
    builder.addCase(getVendorByUniqueKey.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});

export default vendorSlice.reducer;
