import { UserEventGroup } from '@viteplan/models/user-event-group.model';
import httpService from './http.service';

export const getUserById = (userId: number) => {
  return (
    httpService
      .get(`user/${userId}`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

export const getSelectedEventGroup = (userEventGroup: UserEventGroup[], id: number) => {
  if (id === 0) {
    // If no ID is provided, return the first event group from the authenticated user
    return userEventGroup && userEventGroup.length > 0 ? userEventGroup[0]?.eventGroup : {};
  }

  // Find the event group with the provided ID
  const selectedEventGroup = userEventGroup.find((item) => item?.eventGroup?.id === id);
  return selectedEventGroup?.eventGroup || null; // Return null if not found
};
