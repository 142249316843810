import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GreetingList } from '../services/slambook.service';

export const getAllGreetings = createAsyncThunk(
  'getAllMemories',
  async (eventGroupId: number | undefined) => {
    const response = await GreetingList(eventGroupId);
    return response.data;
  }
);

interface SlamBook {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  greetings: [];
  loading: boolean;
  error: string | null;
}

const initialState: SlamBook = {
  greetings: [],
  loading: false,
  error: null
};

export const slambookSlice = createSlice({
  name: 'invitationTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get Greeting List
    builder.addCase(getAllGreetings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllGreetings.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.greetings = data;
    });
    builder.addCase(getAllGreetings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});

export default slambookSlice.reducer;
