/**
 * @summary Maps the provided data to an array of objects in the format required for a dropdown component.
 * @memberof Util {@link Util}
 *
 * @param {any[]} data - The data array to be mapped.
 * @returns {Array<{ value: number; label: string }>} - An array of objects with 'value' and 'label' properties.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const mapDataToDropDown = (data: any): { value: number; label: string } => {
  return data.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
      fontFamily: item.fontFamily
    };
  });
};
