import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { orderListByUser } from '../services/order.service';

export const getOrderListByUser = createAsyncThunk(
  'orderListByUserId',
  async ({ userId, pageNo, pageSize }: { userId: number; pageNo: number; pageSize: number }) => {
    const response = await orderListByUser(userId, pageNo, pageSize);
    return response.data;
  }
);

interface VideoState {
  orderListByUser: [];
  loading: boolean;
  error: null | string;
}

const initialState: VideoState = {
  orderListByUser: [],
  loading: false,
  error: null
};

export const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET ALL Video List
    builder.addCase(getOrderListByUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderListByUser.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      state.orderListByUser = data;
    });
    builder.addCase(getOrderListByUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});

export default orderSlice.reducer;
