import httpService from './http.service';

/**
 * @summary Sends a communication message for a specific event group.
 *
 * @memberof Services {@link Services}
 *
 *
 * @param {number} eventGroupId - The ID of the event group.
 * @param {any} body - The body of the message.
 * @param {object} [config] - Additional configuration options for the HTTP request.
 * @returns {Promise<any>} - A promise that resolves to the response from the server.
 *
 */
export const sendCommunicationMessage = (
  eventGroupId: number | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any,
  config = {}
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    httpService
      .post(`communication/send/${eventGroupId}`, body, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};
