import { Guest } from 'views/Guests/models/guest.model';
import { InvitationMessageData } from '../models/invitation-message.model';
import {
  customMessageTemplate,
  prepareInvitaionMessage,
  requestInfoTemplateEmail,
  rsvpResponseToHost,
  saveTheDateEmailTemplate
} from './helper.service';

/**
 * @summary Prepares a communication message based on the template type and data.
 * @memberof Services {@link Services}
 *
 * @param {string} templateType - The type of message template.
 * @param {InvitationMessageData} data - The data for the message.
 * @returns {object} - The prepared communication message object.
 *
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const prepareCommunicationMessage = (templateType: string, data: InvitationMessageData) => {
  switch (templateType) {
    case 'REQUEST_INFO':
      return {
        subject: `${data?.emailSubject?.firstName} & ${data?.emailSubject?.partnersFirstName}`,
        message: requestInfoTemplateEmail(data?.eventGroupUniqueKey),
        fromName: 'Viteplan',
        salutation: 'Dear XXXSalutation',
        pretext: `We are in the midst of finalizing the details for our upcoming event, and we're eagerly looking forward to celebrating with you`,
        templateType: 'REQUEST_INFO',
        userType: 'GUEST',
        guestIds: data?.guestIds,
        signature: `${data?.emailSubject?.firstName} & ${data?.emailSubject?.partnersFirstName}`,
        toHost: false
      };

    case 'REQUEST_INFO_RESPONSE':
      const firstName = data?.guests?.[0]?.firstName ?? '';
      const lastName = data?.guests?.[0]?.lastName ?? '';
      return {
        subject: `${firstName} ${lastName} submitted their contact information`,
        message: `${firstName} ${lastName} just submitted their contact information. `,
        fromName: 'Viteplan',
        salutation: `Hi ${data.eventGroupDetails?.firstName}`,
        pretext: 'Viteplan - Contact Information Recieved',
        toHost: true,
        signature: '',
        templateType: 'REQUEST_INFO_RESPONSE',
        userType: 'GUEST'
      };

    case 'INVITATION':
      return {
        subject: data?.metaData?.subject
          ? `${data?.metaData.subject}`
          : `${data?.eventGroupDetails?.firstName} & ${data?.eventGroupDetails?.partnersFirstName}`,
        message: prepareInvitaionMessage(data),
        pretext: 'Viteplan - Invitation',
        salutation: 'Hello XXXSalutation',
        toHost: false,
        templateType: 'INVITATION',
        userType: 'GUEST',
        fromName: 'Viteplan',
        signature: `${data?.eventGroupDetails?.firstName} & ${data?.eventGroupDetails?.partnersFirstName}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        guestIds: data?.guests?.map((res: any) => res.id),
        invitationId: data.invitationId,
        communicationType: data.communicationType || 'Email'
      };
    case 'GREETINGS':
      return {
        subject: `Share Memories to ${data?.emailSubject?.firstName} & ${data?.emailSubject?.partnersFirstName}`,
        message: `
        <tr>
        <td align="left" style="
            font-size: 0px;
            padding: 10px 25px;
            word-break: break-word;
          ">
          <div style="
              font-family: 'Helvetica Neue', Helvetica,
                Arial, sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #637381;
            ">
            Share Your Memories from the Event.
          </div>
        </td>
      </tr>
      <tr>
        <td align="center" vertical-align="middle" style="
            font-size: 0px;
            padding: 10px 25px;
            word-break: break-word;
          ">
          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
              border-collapse: separate;
              width: 300px;
              line-height: 100%;
            ">
            <tr>
              <td align="center" bgcolor="#5e6ebf" role="presentation" style="
                  border: none;
                  border-radius: 3px;
                  cursor: auto;
                  mso-padding-alt: 10px 25px;
                  background: #5e6ebf;
                " valign="middle">
                <a href="http://viteplan.com/guest-view/memories/${data?.eventGroupUniqueKey}" style="
                    display: inline-block;
                    width: 250px;
                    background:#f05537;
                    color: #ffffff;
                    font-family: 'Helvetica Neue',
                      Helvetica, Arial, sans-serif;
                    font-size: 17px;
                    font-weight: bold;
                    line-height: 120%;
                    margin: 0;
                    text-decoration: none;
                    text-transform: none;
                    padding: 10px 25px;
                    mso-padding-alt: 0px;
                    border-radius: 3px;
                  " target="_blank">Send Greetings</a>
              </td>
            </tr>
          </table>
        </td>
      </tr>`,
        fromName: 'Viteplan',
        signature: `${data?.emailSubject?.firstName} & ${data?.emailSubject?.partnersFirstName}`,
        salutation: 'Hello XXXSalutation',
        pretext: 'Share Greetings',
        templateType: 'GREETINGS',
        userType: 'GUEST',
        guestIds: data?.guestIds,
        toHost: false
      };

    case 'SAVE_THE_DATE':
      return {
        subject: `Save The Date - ${data?.eventGroupDetails?.firstName} & ${data?.eventGroupDetails?.partnersFirstName}`,
        message: saveTheDateEmailTemplate(data?.saveTheDate?.templateHtml),
        fromName: 'Viteplan',
        salutation: `Hi XXXSalutation`,
        pretext: 'save the date',
        guestIds: data?.guests?.map((guests: Guest) => guests.id),
        toHost: false,
        templateType: 'CUSTOM',
        userType: 'GUEST',
        signature: `${data?.eventGroupDetails?.firstName} & ${data?.eventGroupDetails?.partnersFirstName}`
      };

    case 'CUSTOM_MESSAGE':
    case 'SHARE_WEBSITE':
      return {
        subject: `${data?.metaData.subject}`,
        message: customMessageTemplate(data),
        fromName: data.metaData.from,
        salutation: `Hi XXXSalutation`,
        pretext: 'custom message',
        guestIds: data?.guests?.map((guests: Guest) => guests.id),
        toHost: false,
        templateType: 'CUSTOM',
        userType: 'GUEST',
        signature: `${data?.eventGroupDetails?.firstName} & ${data?.eventGroupDetails?.partnersFirstName}`,
        communicationType: data.communicationType || 'Email'
      };

    case 'RSVP_EMAIL_TO_HOST':
      return {
        subject: `Rsvp Responses`,
        message: rsvpResponseToHost(data.rsvpInfo),
        fromName: 'viteplan',
        salutation: `Hi XXXSalutation`,
        pretext: 'custom message',
        guestIds: [],
        toHost: true,
        templateType: 'CUSTOM',
        userType: 'GUEST',
        signature: `${data?.eventGroupDetails?.firstName} & ${data?.eventGroupDetails?.partnersFirstName}`,
        communicationType: data.communicationType || 'Email'
      };

    case 'Chat_MESSAGE_ON_SMS':
      return {
        subject: `Message`,
        message: `${data.message} from ${data?.eventGroupDetails?.firstName} & ${data?.eventGroupDetails?.partnersFirstName}`,
        fromName: 'viteplan',
        salutation: `Hi XXXSalutation`,
        pretext: 'custom message',
        guestIds: data?.guestIds,
        toHost: false,
        templateType: 'CUSTOM',
        userType: 'GUEST',
        signature: `${data?.eventGroupDetails?.firstName} & ${data?.eventGroupDetails?.partnersFirstName}`,
        communicationType: data.communicationType
      };
  }
};
