/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveTheDateEmailTemplate = (data: any) => {
  return `<!DOCTYPE html>
   ${data} `;
};

export const rsvpResponseToHost = (resultData: any) => {
  // Iterate over resultData to generate HTML for RSVP response
  const html = resultData
    .map((event: any) => {
      const eventHtml = `
      <div>
        <h3>${event.eventName}</h3>
        <ul>
          ${event.guests
            .map((guest: any) => `<li>${guest.guestName} - ${guest.answer}</li>`)
            .join('')}
        </ul>
      </div>
    `;
      return eventHtml;
    })
    .join('');

  // Wrap all event HTMLs inside a container div
  return `<div>${html}</div>`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const requestInfoTemplateEmail = (data: any) => {
  return `<tr>
    <td align="left" style="
        font-size: 0px;
        padding: 10px 25px;
        word-break: break-word;
      ">
      <div style="
          font-family: 'Helvetica Neue', Helvetica,
            Arial, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #637381;
        ">
        We are getting married. But we need your address to send the Invitations and Details. Please fill out this form. Thank you so much.
      </div>
    </td>
  </tr>
  <tr>
    <td align="center" vertical-align="middle" style="
        font-size: 0px;
        padding: 10px 25px;
        word-break: break-word;
      ">
      <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
          border-collapse: separate;
          width: 300px;
          line-height: 100%;
        ">
        <tr>
          <td align="center" bgcolor="#5e6ebf" role="presentation" style="
              border: none;
              border-radius: 3px;
              cursor: auto;
              mso-padding-alt: 10px 25px;
              background: #5e6ebf;
            " valign="middle">
            <a href="http://viteplan.com/guest-view/addr/${data}" style="
                display: inline-block;
                width: 250px;
                background:#f05537;
                color: #ffffff;
                font-family: 'Helvetica Neue',
                  Helvetica, Arial, sans-serif;
                font-size: 17px;
                font-weight: bold;
                line-height: 120%;
                margin: 0;
                text-decoration: none;
                text-transform: none;
                padding: 10px 25px;
                mso-padding-alt: 0px;
                border-radius: 3px;
              " target="_blank">Fill this form</a>
          </td>
        </tr>
      </table>
    </td>
  </tr>`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customMessageTemplate = (data: any) => {
  if (data.communicationType !== 'Email') {
    return `${data.metaData.message} ${data.metaData.from}`;
  } else {
    return `<!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
      xmlns:o="urn:schemas-microsoft-com:office:office">
    
    <head>
      <title>Viteplan</title>
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <!--<![endif]-->
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <style type="text/css">
        #outlook a {
          padding: 0;
        }
    
        body {
          margin: 0;
          padding: 0;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
        }
    
        table,
        td {
          border-collapse: collapse;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
        }
    
        img {
          border: 0;
          height: auto;
          line-height: 100%;
          outline: none;
          text-decoration: none;
          -ms-interpolation-mode: bicubic;
        }
    
        p {
          display: block;
          margin: 13px 0;
        }
      </style>
      <!--[if mso]>
          <noscript>
            <xml>
              <o:OfficeDocumentSettings>
                <o:AllowPNG />
                <o:PixelsPerInch>96</o:PixelsPerInch>
              </o:OfficeDocumentSettings>
            </xml>
          </noscript>
        <![endif]-->
      <!--[if lte mso 11]>
          <style type="text/css">
            .mj-outlook-group-fix {
              width: 100% !important;
            }
          </style>
        <![endif]-->
      <style type="text/css">
        @media only screen and (min-width: 480px) {
          .mj-column-per-100 {
            width: 100% !important;
            max-width: 100%;
          }
    
          .mj-column-per-50 {
            width: 50% !important;
            max-width: 50%;
          }
        }
      </style>
      <style media="screen and (min-width:480px)">
        .moz-text-html .mj-column-per-100 {
          width: 100% !important;
          max-width: 100%;
        }
    
        .moz-text-html .mj-column-per-50 {
          width: 50% !important;
          max-width: 50%;
        }
      </style>
      <style type="text/css">
        @media only screen and (max-width: 480px) {
          table.mj-full-width-mobile {
            width: 100% !important;
          }
    
          td.mj-full-width-mobile {
            width: auto !important;
          }
        }
      </style>
    </head>
    
    <body style="word-spacing: normal; background-color: #e7e7e7">
      <div style="
            display: none;
            font-size: 1px;
            color: #ffffff;
            line-height: 1px;
            max-height: 0px;
            max-width: 0px;
            opacity: 0;
            overflow: hidden;
          ">
        <!-- Pre-header Text -->
        Viteplan
      </div>
      <div style="background-color: #e7e7e7">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
          style="background: #fff; background-color: #fff; width: 100%">
          <tbody>
            <tr>
              <td>
                <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#040B4F" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                <div style="margin: 0px auto; max-width: 600px">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width: 100%">
                    <tbody>
                      <tr>
                        <td style="
                              direction: ltr;
                              font-size: 0px;
                              padding: 20px 0;
                              padding-bottom: 0;
                              text-align: center;
                            ">
                          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                          <div class="mj-column-per-100 mj-outlook-group-fix" style="
                                font-size: 0px;
                                text-align: left;
                                direction: ltr;
                                display: inline-block;
                                vertical-align: top;
                                width: 100%;
                              ">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                              style="vertical-align: top" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" style="
                                        font-size: 0px;
                                        padding: 10px 25px;
                                        word-break: break-word;
                                      ">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        ">
                                      <tbody>
                                        <tr>
                                          <td style="width: 150px">
                                            <img alt height="auto"
                                              src="https://viteplan.s3.amazonaws.com/logo/viteplan-logo.png" style="
                                                  border: 0;
                                                  display: block;
                                                  outline: none;
                                                  text-decoration: none;
                                                  height: auto;
                                                  width: 100%;
                                                  font-size: 13px;
                                                " width="150" />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" style="
                                        font-size: 0px;
                                        padding: 0;
                                        word-break: break-word;
                                      ">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
                                          border-collapse: collapse;
                                          border-spacing: 0px;
                                        ">
                                      <tbody>
                                        <tr>
                                          <td style="width: 600px">
                                            <a href="https://google.com" target="_blank"><img alt height="auto"
                                                src="https://viteplan.s3.amazonaws.com/app-assets/banner.png" style="
                                                    border: 0;
                                                    display: block;
                                                    outline: none;
                                                    text-decoration: none;
                                                    height: auto;
                                                    width: 100%;
                                                    font-size: 13px;
                                                  " width="600" /></a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!--[if mso | IE]></td></tr></table><![endif]-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#1f2e78" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
        <div style="
              background: #e45b2b;
              background-color: #e45b2b;
              margin: 0px auto;
              max-width: 600px;
            ">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
            style="background: #e45b2b; background-color: #e45b2b; width: 100%">
            <tbody>
              <tr>
                <td style="
                      direction: ltr;
                      font-size: 0px;
                      padding: 20px 0;
                      text-align: center;
                    ">
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                  <div class="mj-column-per-100 mj-outlook-group-fix" style="
                        font-size: 0px;
                        text-align: left;
                        direction: ltr;
                        display: inline-block;
                        vertical-align: top;
                        width: 100%;
                      ">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top"
                      width="100%">
                      <tbody>
                        <tr>
                          <td align="center" style="
                                font-size: 0px;
                                padding: 0;
                                word-break: break-word;
                              ">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                ">
                              <tbody>
                                <tr>
                                  <td style="width: 600px">
                                    <a href="https://google.com" target="_blank" style="
                                      text-decoration: none;
                                  ">
                                      <table role="presentation"
                                        style="background:#e45b2b;background-color:#e45b2b;width:100%;" cellspacing="0"
                                        cellpadding="0" border="0">
                                        <tbody>
    
                                          <tr>
                                            <td>
                                              <div style="text-align:center;">
                                                <p style="margin: 0 0 15px 0; color:#fff; font-size:40px;">${data?.metaData?.from}</p>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table role="presentation" style="border-collapse:collapse;border-spacing:0px;"
                                        cellspacing="0" cellpadding="0" border="0" align="center">
                                        <tbody>
    
                                          <tr>
                                            <td>
                                              <img src="https://viteplan.s3.amazonaws.com/app-assets/seperator.png"
                                                style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;"
                                                width="" height="auto">
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
    
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="body-section-outlook" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
        <div class="body-section" style="
              -webkit-box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.15);
              -moz-box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.15);
              box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.15);
              margin: 0px auto;
              max-width: 600px;
            ">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width: 100%">
            <tbody>
              <tr>
                <td style="
                      direction: ltr;
                      font-size: 0px;
                      padding: 0px 0;
                      padding-bottom: 0;
                      padding-top: 0;
                      text-align: center;
                    ">
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" width="600px" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                  <div style="
                        background: #ffffff;
                        background-color: #ffffff;
                        margin: 0px auto;
                        max-width: 600px;
                      ">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="
                          background: #ffffff;
                          background-color: #ffffff;
                          width: 100%;
                        ">
                      <tbody>
                        <tr>
                          <td style="
                                direction: ltr;
                                font-size: 0px;
                                padding: 20px 0;
                                padding-left: 15px;
                                padding-right: 15px;
                                text-align: center;
                              ">
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:570px;" ><![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix" style="
                                  font-size: 0px;
                                  text-align: left;
                                  direction: ltr;
                                  display: inline-block;
                                  vertical-align: top;
                                  width: 100%;
                                ">
                              <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                style="vertical-align: top" width="100%">
                                <tbody>
    
                                  <tr>
                                    <td align="left" style="
                                        font-size: 0px;
                                        padding: 10px 25px;
                                        word-break: break-word;
                                      ">
                                      <!--<div style="
                                          font-family: 'Helvetica Neue', Helvetica,
                                            Arial, sans-serif;
                                          font-size: 16px;
                                          font-weight: 400;
                                          line-height: 24px;
                                          text-align: left;
                                          color: #637381;
                                        ">
                                        XXXSALUTATION
                                      </div>-->
                                    </td>
                                  </tr>
                                  <tr>
                                  <td align="left" style="
                                      font-size: 0px;
                                      padding: 10px 25px;
                                      word-break: break-word;
                                    ">
                                    <div style="
                                        font-family: 'Helvetica Neue', Helvetica,
                                          Arial, sans-serif;
                                        font-size: 16px;
                                        font-weight: 400;
                                        line-height: 24px;
                                        text-align: left;
                                        color: #637381;
                                      ">
                                    ${data?.metaData?.message}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" vertical-align="middle" style="
                                      font-size: 0px;
                                      padding: 10px 25px;
                                      word-break: break-word;
                                    ">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
                                        border-collapse: separate;
                                        width: 300px;
                                        line-height: 100%;
                                      ">
                                      <tr>
                                        <td align="center" bgcolor="#5e6ebf" role="presentation" style="
                                            border: none;
                                            border-radius: 3px;
                                            cursor: auto;
                                            mso-padding-alt: 10px 25px;
                                            background: #5e6ebf;
                                          " valign="middle">
                                          <a href=${data?.metaData?.link} style="
                                              display: inline-block;
                                              width: 250px;
                                              background:#f05537;
                                              color: #ffffff;
                                              font-family: 'Helvetica Neue',
                                                Helvetica, Arial, sans-serif;
                                              font-size: 17px;
                                              font-weight: bold;
                                              line-height: 120%;
                                              margin: 0;
                                              text-decoration: none;
                                              text-transform: none;
                                              padding: 10px 25px;
                                              mso-padding-alt: 0px;
                                              border-radius: 3px;
                                            " target="_blank">${data?.metaData?.buttonText}</a>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
  
                                </tbody>
                              </table>
                            </div>
                            <!--[if mso | IE]></td></tr></table><![endif]-->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
    
                  <div style="
                        background: #ffffff;
                        background-color: #ffffff;
                        margin: 0px auto;
                        max-width: 600px;
                      ">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="
                          background: #ffffff;
                          background-color: #ffffff;
                          width: 100%;
                        ">
                      <tbody>
                        <tr>
                          <td style="
                                direction: ltr;
                                font-size: 0px;
                                padding: 20px 0;
                                padding-left: 15px;
                                padding-right: 15px;
                                padding-top: 0;
                                text-align: center;
                              ">
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:570px;" ><![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix" style="
                                  font-size: 0px;
                                  text-align: left;
                                  direction: ltr;
                                  display: inline-block;
                                  vertical-align: top;
                                  width: 100%;
                                ">
                              <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                style="vertical-align: top" width="100%">
                                <tbody>
                                  <tr>
                                    <td align="center" style="
                                          font-size: 0px;
                                          padding: 10px 25px;
                                          word-break: break-word;
                                        ">
                                      <p style="
                                            border-top: solid 1px #dfe3e8;
                                            font-size: 1px;
                                            margin: 0px auto;
                                            width: 100%;
                                          "></p>
                                      <!--[if mso | IE
                                          ]><table
                                            align="center"
                                            border="0"
                                            cellpadding="0"
                                            cellspacing="0"
                                            style="
                                              border-top: solid 1px #dfe3e8;
                                              font-size: 1px;
                                              margin: 0px auto;
                                              width: 520px;
                                            "
                                            role="presentation"
                                            width="520px"
                                          >
                                            <tr>
                                              <td style="height: 0; line-height: 0">
                                                &nbsp;
                                              </td>
                                            </tr>
                                          </table><!
                                        [endif]-->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <!--[if mso | IE]></td></tr></table><![endif]-->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if mso | IE]></td></tr></table></td></tr><tr><td class="" width="600px" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                  <div style="
                        background: #ffffff;
                        background-color: #ffffff;
                        margin: 0px auto;
                        max-width: 600px;
                      ">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="
                          background: #ffffff;
                          background-color: #ffffff;
                          width: 100%;
                        ">
                      <tbody>
                        <tr>
                          <td style="
                                direction: ltr;
                                font-size: 0px;
                                padding: 0px 0;
                                padding-left: 0px;
                                padding-right: 0px;
                                text-align: center;
                              ">
                            <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:570px;" ><![endif]-->
                            <div class="mj-column-per-100 mj-outlook-group-fix" style="
                                  font-size: 0px;
                                  text-align: left;
                                  direction: ltr;
                                  display: inline-block;
                                  vertical-align: top;
                                  width: 100%;
                                ">
                              <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                style="vertical-align: top" width="100%">
                                <tbody>
                                  <tr>
                                    <td align="center" style="
                                          font-size: 0px;
                                          word-break: break-word;
                                        ">
                                      <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                          ">
                                        <tbody>
                                          <tr>
                                            <td style="">
                                              <img alt height="auto"
                                                src="https://viteplan.s3.amazonaws.com/app-assets/footer.png" style="
                                                    border: 0;
                                                    display: block;
                                                    outline: none;
                                                    text-decoration: none;
                                                    height: auto;
                                                    width: 100%;
                                                    font-size: 13px;
                                                  " width="520" />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <!--[if mso | IE]></td></tr></table><![endif]-->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if mso | IE]></td></tr></table></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[if mso | IE]></td></tr></table><![endif]-->
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width: 100%">
          <tbody>
            <tr>
              <td>
                <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                <div style="margin: 0px auto; max-width: 600px">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width: 100%">
                    <tbody>
                      <tr>
                        <td style="
                              direction: ltr;
                              font-size: 0px;
                              padding: 20px 0;
                              text-align: center;
                            ">
                          <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" width="600px" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                          <div style="margin: 0px auto; max-width: 600px">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                              style="width: 100%">
                              <tbody>
                                <tr>
                                  <td style="
                                        direction: ltr;
                                        font-size: 0px;
                                        padding: 20px 0;
                                        text-align: center;
                                      ">
                                    <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                                    <div class="mj-column-per-100 mj-outlook-group-fix" style="
                                          font-size: 0px;
                                          text-align: left;
                                          direction: ltr;
                                          display: inline-block;
                                          vertical-align: top;
                                          width: 100%;
                                        ">
                                      <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                                        <tbody>
                                          <tr>
                                            <td style="
                                                  vertical-align: top;
                                                  padding: 0;
                                                ">
                                              <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                width="100%">
                                                <tbody>
                                                  <!-- FOOTER CONTENT TR's-->
    
    
                                                  <tr>
                                                    <td align="center" style="
                                                          font-size: 0px;
                                                          padding: 10px 25px;
                                                          word-break: break-word;
                                                        ">
                                                      <div style="
                                                            font-family: 'Helvetica Neue',
                                                              Helvetica, Arial,
                                                              sans-serif;
                                                            font-size: 11px;
                                                            font-weight: 400;
                                                            line-height: 16px;
                                                            text-align: center;
                                                            color: #445566;
                                                          ">
                                                        To ensure you receive all future invites from your
                                                        friends, please add
                                                        <a target="_blank" style="color: #00499b"
                                                          href="mailto:info@viteplan.com">info@viteplan.com</a>
                                                        to your address book.
                                                        <!-- <a target="_blank" style="color: #00499b" href="">Click here</a>
                                                        to stop receiving emails from VitePlan. We are
                                                        committed to protecting your privacy, view our
                                                        <a style="color: #00499b" href="">privacy policy</a>. If you need
                                                        assistance, please
                                                        <a style="color: #00499b" target="_blank" href="">contact us</a>. -->
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td align="center" style="
                                                          font-size: 0px;
                                                          padding: 10px 25px;
                                                          word-break: break-word;
                                                        ">
                                                      <div style="
                                                            font-family: 'Helvetica Neue',
                                                              Helvetica, Arial,
                                                              sans-serif;
                                                            font-size: 11px;
                                                            font-weight: 400;
                                                            line-height: 16px;
                                                            text-align: center;
                                                            color: #445566;
                                                          ">
                                                        &copy; Viteplan
                                                        Inc., All Rights Reserved.
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if mso | IE]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!--[if mso | IE]></td></tr></table></td></tr><tr><td class="" width="600px" ><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
                          <div style="margin: 0px auto; max-width: 600px">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                              style="width: 100%">
                              <tbody>
                                <tr>
                                  <td style="
                                        direction: ltr;
                                        font-size: 0px;
                                        padding: 20px 0;
                                        padding-top: 0;
                                        text-align: center;
                                      ">
                                    <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="width:600px;" ><![endif]-->
                                    <div class="mj-column-per-100 mj-outlook-group-fix" style="
                                          font-size: 0;
                                          line-height: 0;
                                          text-align: left;
                                          display: inline-block;
                                          width: 100%;
                                          direction: ltr;
                                        ">
                                      <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:600px;" ><![endif]-->
                                      <div class="mj-column-per-100 mj-outlook-group-fix" style="
                                            font-size: 0px;
                                            text-align: left;
                                            direction: ltr;
                                            display: inline-block;
                                            vertical-align: top;
                                            width: 100%;
                                          ">
                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                                          <tbody>
                                            <tr>
                                              <td style="
                                                    vertical-align: top;
                                                    padding-right: 0;
                                                  ">
                                                <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                  width="100%">
                                                  <tbody>
                                                    <tr>
                                                      <td align="center" style="
                                                            font-size: 0px;
                                                            padding: 10px 25px;
                                                            word-break: break-word;
                                                          ">
                                                        <div style="
                                                              font-family: 'Helvetica Neue',
                                                                Helvetica, Arial,
                                                                sans-serif;
                                                              font-size: 11px;
                                                              font-weight: bold;
                                                              line-height: 16px;
                                                              text-align: center;
                                                              color: #445566;
                                                            ">
                                                          <a class="footer-link" href=""
                                                            style="color: #888888">Privacy</a>&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;<a
                                                            class="footer-link" href=""
                                                            style="color: #888888">Unsubscribe</a>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <!--[if mso | IE]></td></tr></table><![endif]-->
                                    </div>
                                    <!--[if mso | IE]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!--[if mso | IE]></td></tr></table></td></tr></table><![endif]-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </body>
    
    </html>`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const prepareInvitaionMessage = (data: any) => {
  if (data.communicationType !== 'Email') {
    return `${data.metaData.message} ${data.metaData.from}`;
  } else {
    return ` <tr>
    <td align="left" style="
        font-size: 0px;
        padding: 10px 25px;
        word-break: break-word;
      ">
      <div style="
          font-family: 'Helvetica Neue', Helvetica,
            Arial, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #637381;
        ">
        ${data?.eventGroupDetails?.firstName} & ${data?.eventGroupDetails?.partnersFirstName}  ${
      data.metaData ? data.metaData.message : 'Sent you a New Invitation'
    }
      </div>
    </td>
  </tr>
  <tr>
    <td align="center" vertical-align="middle" style="
        font-size: 0px;
        padding: 10px 25px;
        word-break: break-word;
      ">
      <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
          border-collapse: separate;
          width: 300px;
          line-height: 100%;
        ">
        <tr>
          <td align="center" bgcolor="#5e6ebf" role="presentation" style="
              border: none;
              border-radius: 3px;
              cursor: auto;
              mso-padding-alt: 10px 25px;
              background: #5e6ebf;
            " valign="middle">
            <a href="https://viteplan.com/invitation/guest-invitation/XXXINVITATION_ID" style="
                display: inline-block;
                width: 250px;
                background:#f05537;
                color: #ffffff;
                font-family: 'Helvetica Neue',
                  Helvetica, Arial, sans-serif;
                font-size: 17px;
                font-weight: bold;
                line-height: 120%;
                margin: 0;
                text-decoration: none;
                text-transform: none;
                padding: 10px 25px;
                mso-padding-alt: 0px;
                border-radius: 3px;
              " target="_blank">View Your Invitation</a>
          </td>
        </tr>
      </table>
    </td>
  </tr>`;
  }
};
