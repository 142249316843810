import { DropDown } from '@viteplan/components/common/DropDown';
import { getCountries, getStates } from '@viteplan/services/master-data.service';
import { getSelectedEventGroup } from '@viteplan/services/user.service';
import { mapDataToDropDown } from '@viteplan/util/dropdown.util';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { addGroupChatMembers } from 'views/Messages/services/message.service';
import { getGroupMessage } from 'views/Messages/slice/message.slice';
import * as Yup from 'yup';
import { useGlobalDrawerContext } from '../../../context-api/GlobalDrawerContext';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import useAuth from '../../../hooks/useAuth';
import { getAllEvents } from '../../Events/slice/event-slice';
import { Guest, GuestGroup } from '../models/guest.model';
import { getGuestGroupTags, updateInvitedFor } from '../services/guest.service';
import { addGuestData, clearGuestError, getAllGuests } from '../slice/guest.slice';
import { EventDetails } from 'views/Events/models/event-details.model';

const guestValidationSchema = Yup.object().shape({
  guests: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required('First Name is required')
      // email: Yup.string().required('Email is required')
    })
  )
  // guestAddresses: Yup.array().of(
  //   Yup.object().shape({
  //     country: new Yup.ObjectSchema().required('Country is Required')
  //   })
  // )
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function AddIndividualGuest(props: any) {
  const { auth } = useAuth();
  const { drawerOpenState = true, setDrawerOpenState } = props;
  const dispatch = useAppDispatch();
  const { hideDrawer } = useGlobalDrawerContext();

  const { eventGroup } = useAppSelector((state) => state.event);
  const { groupMessage } = useAppSelector((state) => state.messages);
  const { error } = useAppSelector((state) => state.guest);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [groupTags, setGuestGroupTags] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(1);
  const [enabledDropdowns, setEnabledDropdowns] = useState<Record<number, boolean>>({});
  const [invitedFor, setInvitedForArray] = useState<EventDetails[]>([]);
  const eventGroupIdString = localStorage.getItem('selectedEventGroupId');
  const eventGroupId = eventGroupIdString ? parseInt(eventGroupIdString) : 0;
  const userEventGroup = getSelectedEventGroup(auth.user.userEventGroups, eventGroupId);
  const [guestDetails, setGuestDetails] = useState<GuestGroup>();
  const [noOfAllowedGuestByHost, setNoOfAllowedGuestByHost] = useState<number>(1);

  const initCountries = async () => {
    const response = await getCountries();
    setCountries(response.data);
  };

  const initStates = async () => {
    const response = await getStates(selectedCountryId || 1);
    setStates(response.data);
  };

  const initGuestGroupTags = async () => {
    const response = await getGuestGroupTags(userEventGroup?.id);
    setGuestGroupTags(response?.data?.data);
  };

  // Get All Events
  useEffect(() => {
    dispatch(getAllEvents(userEventGroup?.id));
    dispatch(
      getGroupMessage({
        eventGroupId: userEventGroup?.id,
        guestId: undefined,
        userId: auth?.user.id
      })
    );
  }, []);

  //Country
  useEffect(() => {
    initCountries();
  }, []);

  //State
  useEffect(() => {
    initStates();
  }, [selectedCountryId]);

  //Guest Group Tags
  useEffect(() => {
    initGuestGroupTags();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT
      });
      dispatch(clearGuestError());
    }
  }, [error]);

  const guestFormState = {
    guests: [
      {
        firstName: '',
        lastName: '',
        email: null,
        mobile: '',
        guestGroupTags: []
      }
    ],
    guestAddresses: [
      {
        address: '',
        apartment: '',
        city: '',
        country: null,
        zip: ''
      }
    ],
    guestNotes: [
      {
        message: ''
      }
    ],
    testInvitedFor: [{ eventId: 0, noOfGuestsAllowed: 0 }],
    noOfGuestsAllowed: 1
  };

  const addInvitedForData = async () => {
    try {
      if (!guestDetails) return;

      const response = await updateInvitedFor(guestDetails.id, invitedFor);
      if (response.data.data) {
        dispatch(getAllGuests(userEventGroup?.id));
        !drawerOpenState ? setDrawerOpenState(true) : hideDrawer();
      }
    } catch (e) {
      console.log('Error Ocuured');
    }
  };

  const handleCheckboxChange = (eventId: number, noOfAllowedGuests = noOfAllowedGuestByHost) => {
    setEnabledDropdowns((prevState: Record<number, boolean>) => {
      const newState = { ...prevState, [eventId]: !prevState[eventId] };
      if (!newState[eventId]) {
        setInvitedForArray((prevArray) => prevArray.filter((item) => item.eventId !== eventId));
      } else {
        setInvitedForArray((prevArray) => [...prevArray, { eventId, noOfAllowedGuests }]);
      }
      return newState;
    });
  };

  const handleDropdownChange = (eventId: number, value: number) => {
    setInvitedForArray((prevArray) => {
      const index = prevArray.findIndex((item) => item.eventId === eventId);
      if (index !== -1) {
        return prevArray.map((item, i) =>
          i === index ? { ...item, noOfAllowedGuests: value } : item
        );
      } else {
        return [...prevArray, { eventId, noOfAllowedGuests: value }];
      }
    });
  };

  const handleAddAllowedGuest = (eventId: number, allowedGuestId: number) => {
    const eventIndex = invitedFor.findIndex((item) => item.eventId === eventId);
    if (eventIndex !== -1) {
      const updatedInvitedFor = [...invitedFor];
      const allowedGuestIds = updatedInvitedFor[eventIndex].allowedGuestIds || [];
      const guestIndex = allowedGuestIds.indexOf(allowedGuestId);
      if (guestIndex !== -1) {
        allowedGuestIds.splice(guestIndex, 1);
      } else {
        allowedGuestIds.push(allowedGuestId);
      }
      updatedInvitedFor[eventIndex].allowedGuestIds = allowedGuestIds;
      setInvitedForArray(updatedInvitedFor);
    }
  };

  const addGuestToDefaultChatGroup = async (data: { guests: Guest[] }) => {
    try {
      const newGuest = data.guests.map((item: Guest) => ({
        guest: { id: item.id }
      }));
      const defaultGroup = groupMessage.find(
        (item: { isDefault: boolean }) => item.isDefault === true
      );
      if (!defaultGroup) {
        console.log('No default group found.');
        return;
      }
      const groupId = defaultGroup.id;
      const request = newGuest.map((guest) => ({
        guest: guest.guest
      }));
      await addGroupChatMembers(groupId, request);
    } catch (e) {
      console.log('Error Occurred', e);
    }
  };

  return (
    <>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      /> */}
      <Formik
        initialValues={guestFormState}
        validationSchema={guestValidationSchema}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSubmit={(values: any) => {
          const request = {
            guests: [...values.guests],
            guestAddresses: [...values.guestAddresses],
            guestNotes: [...values.guestNotes],
            invitedFor: [],
            eventGroupId: userEventGroup?.id,
            noOfGuestsAllowed: values.noOfGuestsAllowed
          };
          dispatch(addGuestData(request)).then((response) => {
            if (response.payload.data) {
              toast.success('Guest Updated Successfully');
              setGuestDetails(response.payload.data);
              addGuestToDefaultChatGroup(response.payload.data);
              dispatch(getAllGuests(userEventGroup?.id));
            }
          });
        }}>
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="group_field_container gap-4" style={{ maxWidth: '100%' }}>
              <FieldArray name="guests">
                {({ push, remove }) => (
                  <div>
                    {/*  eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    {values.guests.map((p: any, index: number) => {
                      const firstName = `guests[${index}].firstName`;
                      const lastName = `guests[${index}].lastName`;
                      const email = `guests[${index}].email`;
                      const mobile = `guests[${index}].mobile`;
                      const guestGroupTags = `guests[${index}].guestGroupTags`;
                      return (
                        <div
                          className="multi_field_container new_guest_add flex-wrap justify-content-end"
                          key={index}>
                          <div className="w-100 d-flex gap-4 divider">
                            <span className="field_container w-50">
                              <label className="field_title">First Name*</label>
                              <input
                                type="text"
                                name={firstName}
                                value={p.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                className="field_error"
                                name={`guests[${index}].firstName`}
                                component="span"
                              />
                            </span>
                            <span className="field_container w-50">
                              <label className="field_title">Last Name</label>
                              <input
                                type="text"
                                name={lastName}
                                value={p.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </span>
                          </div>
                          <div className="w-100 d-flex gap-4 divider">
                            <span className="field_container w-50">
                              <label className="field_title">Email</label>
                              <input
                                type="email"
                                name={email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={p.email}
                              />
                              <ErrorMessage
                                className="field_error"
                                name={`guests[${index}].email`}
                                component="span"
                              />
                            </span>
                            <span className="field_container w-50">
                              <label className="field_title">Mobile Number</label>
                              <Field name={mobile}>
                                {
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  ({ field }: any) => (
                                    <PhoneInput
                                      {...field}
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      onChange={(value: any) => {
                                        handleChange({
                                          target: {
                                            name: field.name,
                                            value: value
                                          }
                                        });
                                      }}
                                      defaultCountry="US"
                                      onBlur={handleBlur}
                                      placeholder="Enter phone number"
                                      className="box-with-same_input phone_input_country"
                                    />
                                  )
                                }
                              </Field>
                            </span>
                          </div>
                          <span className="field_container w-100">
                            <label className="field_title">Tags</label>
                            <DropDown
                              id="tags"
                              name={guestGroupTags}
                              isMultiSelect={true}
                              options={mapDataToDropDown(groupTags)}
                              onChange={(option: { value: number; label: string }[]) => {
                                if (option) {
                                  const formattedOptions = option.map((option) => ({
                                    id: option.value,
                                    name: option.label
                                  }));
                                  setFieldValue(guestGroupTags, formattedOptions);
                                } else {
                                  setFieldValue(guestGroupTags, []);
                                }
                              }}
                            />
                          </span>

                          <button
                            className="btn without-border position-relative p-0 d-flex gap-1"
                            style={{
                              minWidth: 'unset',
                              top: '-10px',
                              fontSize: '13px',
                              color: '#f05537',
                              fontWeight: '400',
                              maxWidth: 'fit-content',
                              alignSelf: 'end'
                            }}
                            type="button"
                            onClick={() => remove(index)}>
                            <i className="fal fa-times"></i> Remove
                          </button>
                        </div>
                      );
                    })}
                    <span className="field_container w-50">
                      <label className="field_title">No of Guest Allowed</label>
                      <DropDown
                        name="noOfGuestsAllowed"
                        onChange={(option: { value: number; label: string }) => {
                          setFieldValue('noOfGuestsAllowed', option.value);
                          setNoOfAllowedGuestByHost(option.value);
                        }}
                        options={mapDataToDropDown(
                          [...Array(10).keys()].map((index) => ({ id: index + 1, name: index + 1 }))
                        )}
                        selectedOptions={{
                          id: values.noOfGuestsAllowed,
                          name: values.noOfGuestsAllowed
                        }}
                      />
                    </span>
                    <button
                      className="btn without-border p-0 gap-1 my-3"
                      type="button"
                      onClick={() =>
                        push({
                          email: null,
                          firstName: '',
                          lastName: '',
                          mobile: ''
                        })
                      }>
                      <i className="fal fa-plus" />
                      Add related guests or a plus one
                    </button>
                  </div>
                )}
              </FieldArray>

              <h1 className="title_small form_group_title">Contact information</h1>
              <div className="multi_field_container">
                <span className="field_container w-50">
                  <label className="field_title">Address</label>
                  <input
                    type="text"
                    name="guestAddresses[0].address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.guestAddresses[0].address}
                  />
                </span>
                <span className="field_container w-50">
                  <label className="field_title">Apt/Floor</label>
                  <input
                    type="text"
                    name="guestAddresses[0].apartment"
                    value={values.guestAddresses[0].apartment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </span>
              </div>
              <div className="multi_field_container">
                <span className="field_container  w-50">
                  <label className="field_title">Country</label>
                  <DropDown
                    id="countryId"
                    onChange={(option: { value: number; label: string }) => {
                      setFieldValue(`guestAddresses.${[0]}.country`, {
                        id: option.value,
                        name: option.label
                      });
                      setSelectedCountryId(option.value);
                    }}
                    options={mapDataToDropDown(countries)}
                  />
                  <ErrorMessage
                    className="field_error"
                    name={`guestAddresses[${0}].country`}
                    component="span"
                  />
                </span>
                <span className="field_container w-50">
                  <label className="field_title">State</label>
                  <DropDown
                    id="stateId"
                    name="state"
                    onChange={(option: { value: number; label: string }) => {
                      setFieldValue(`guestAddresses.${[0]}.state`, {
                        id: option.value,
                        name: option.label
                      });
                    }}
                    options={mapDataToDropDown(states)}
                  />
                  <ErrorMessage
                    className="field_error"
                    name={`guestAddresses[${0}].state`}
                    component="span"
                  />
                </span>
              </div>
              <div className="multi_field_container">
                <span className="field_container w-50">
                  <label className="field_title">City</label>
                  <input
                    type="text"
                    name="guestAddresses[0].city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.guestAddresses[0].city}
                  />
                </span>
                <span className="field_container w-50">
                  <label className="field_title">Zip</label>
                  <input
                    type="text"
                    name="guestAddresses[0].zip"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.guestAddresses[0].zip}
                  />
                </span>
              </div>
              <span className="field_container">
                <label className="field_title">Note for Guests</label>
                <textarea
                  style={{ height: '75px' }}
                  name="guestNotes[0].message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.guestNotes[0].message}
                />
              </span>
              <button type="submit" className="btn primary" style={{ maxWidth: 'fit-content' }}>
                Save
              </button>
              <label style={{ color: '#f05537' }}>
                Save your guests before inviting them to an Event
              </label>
              <div
                style={{
                  opacity: !guestDetails ? 0.5 : 1,
                  pointerEvents: !guestDetails ? 'none' : 'auto'
                }}>
                {' '}
                <h1 className="title_small form_group_title">Invited For</h1>
                <div className="invited-for d-flex mt-4 flex-wrap flex-column gap-3">
                  {eventGroup && eventGroup.events && eventGroup?.events.length > 0 ? (
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    eventGroup?.events?.map((item: any, index: number) => {
                      return (
                        <div className="list_box_invite d-flex gap-4 w-100 " key={index}>
                          <div className="event_name_list d-flex gap-2 flex-wrap">
                            <div className="Checkbox" style={{ marginTop: '4px' }}>
                              <input
                                type="checkbox"
                                id={item.id}
                                checked={enabledDropdowns[item.id]}
                                value={item}
                                onChange={() => {
                                  handleCheckboxChange(item.id, item.noOfGuestAllowed);
                                }}
                              />
                              <div className="Checkbox-visible"></div>
                            </div>
                            <div className="list_text_invite" style={{ flex: '1' }}>
                              <div
                                className="event_text "
                                style={{ fontSize: '18px', fontWeight: '100' }}>
                                {' '}
                                <p style={{ paddingBottom: '0', fontWeight: '500' }}>
                                  {' '}
                                  {item?.name}{' '}
                                  {item.isRSVPRestricted && (
                                    <span className="info_tooltip_panel">
                                      <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        data-tooltip-id="rsvp_restricted_info"></i>
                                      <ReactTooltip id="rsvp_restricted_info">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                          <span>This is a restricted RSVP Event</span>
                                          <span>
                                            You have to select the invitees name for this event.
                                          </span>
                                        </div>
                                      </ReactTooltip>
                                    </span>
                                  )}
                                </p>
                                <p style={{ paddingBottom: '0', fontSize: '14px' }}>
                                  {' '}
                                  {item?.date}
                                </p>
                              </div>
                            </div>
                          </div>
                          {enabledDropdowns[item.id] && (
                            <>
                              <div className="allowed_guest_panel">
                                <div
                                  style={{
                                    display: item?.isRSVPRestricted ? 'none' : 'block'
                                  }}>
                                  <label>Allowed guests</label>
                                  <DropDown
                                    offSet={101}
                                    disabled={!enabledDropdowns[item.id]}
                                    name="noOfGuestsAllowed"
                                    onChange={(option: { value: number; label: string }) => {
                                      handleDropdownChange(item.id, option.value);
                                    }}
                                    options={mapDataToDropDown(
                                      [...Array(noOfAllowedGuestByHost).keys()].map((index) => ({
                                        id: index + 1,
                                        name: index + 1
                                      }))
                                    )}
                                    selectedOptions={{
                                      id: item.noOfGuestsAllowed || 1,
                                      name: item.noOfGuestsAllowed || 1
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="guest_child_panel">
                                {item.isRSVPRestricted &&
                                  guestDetails?.guests.map((guest: Guest, index: number) => {
                                    return (
                                      <div
                                        style={{
                                          display: 'inline-flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          width: '20%'
                                        }}>
                                        <label> {guest?.firstName}</label>
                                        <div className="Checkbox" key={index}>
                                          <input
                                            type="checkbox"
                                            id={`${item.id}-${guest.id}`}
                                            // onChange={() => {
                                            //   handleAddAllowedGuest(item.id, guest.id);
                                            // }}
                                            onChange={() => {
                                              if (guest.id !== undefined) {
                                                handleAddAllowedGuest(item.id, guest.id);
                                              }
                                            }}
                                          />
                                          <div className="Checkbox-visible"></div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <p>
                      You don't have any events created. Please create events first before inviting
                      the guests.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <button className="btn primary" onClick={() => addInvitedForData()}>
        Save
      </button>
    </>
  );
}

export default AddIndividualGuest;
