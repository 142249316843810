import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userTemplate } from '../services/editor.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addUserTemplate = createAsyncThunk('userTemplate', async (data: any) => {
  const response = await userTemplate(data);
  return response.data;
});

interface UserTemplate {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userTemplate: any;
  loading: boolean;
  error: string | null;
}

const initialState: UserTemplate = {
  userTemplate: {},
  loading: false,
  error: null
};

export const editorSlice = createSlice({
  name: 'userTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Add User Teamplate
    builder.addCase(addUserTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addUserTemplate.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action.payload;
      /* state.userTemplate = {
        id: data.id
      }; */
      state.userTemplate = data;
    });
    builder.addCase(addUserTemplate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});

export default editorSlice.reducer;
